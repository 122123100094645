import React, { useState, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
} from "devextreme-react/form";
import LoadIndicator from "devextreme-react/load-indicator";
import notify from "devextreme/ui/notify";
import { useAuth } from "../../contexts/auth";

import "./LoginForm.scss";
import i18next, { t } from "i18next";
import i18n from "../../i18n";

const currentLocal =  navigator.language;
const signInLabel = currentLocal.includes("en") ? "Sign In":"Inloggen";

export default function LoginForm() {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const formData = useRef({ username: "", password: "" });
  const onSubmit = useCallback(
    async (e: any) => {

      e.preventDefault();
      const { username, password } = formData.current;
      setLoading(true);

      const result = await signIn(username, password);
      if (!result.isOk) {
        setLoading(false);
        notify(result.message, "error", 2000);
      } else {
        i18next.changeLanguage(currentLocal.includes("en") ? "en" : "nl");
        navigate("/profile");
      }
    },
    [signIn, navigate]
  );

  return (
     
    <form className={"login-form"} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
          <Item dataField="username" editorOptions={emailEditorOptions}>
          <RequiredRule message={t("Username is required")} />
          <Label visible={false} />
          </Item>
            <Item
              dataField="password"
              editorType={"dxTextBox"}
              editorOptions={passwordEditorOptions}
            >
            <RequiredRule message= {t("Password is required")} />
            <Label visible={false} />
          </Item>
        <ButtonItem>
          <ButtonOptions
            width={"100%"}
            type={"default"}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {loading ? (
                <LoadIndicator width={"24px"} height={"24px"} visible={true} />
              ) : (
                signInLabel
              )}
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
    </form>
  );
}

const emailEditorOptions = {
  stylingMode: "filled",
  placeholder: currentLocal.includes("en") ? "Username" : "Gebruikersnaam" , 
};

const passwordEditorOptions = {
  stylingMode: "filled",
  placeholder: currentLocal.includes("en") ? "Password" : "Wachtwoord",
  mode: "password",
};
