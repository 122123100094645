import { t } from "i18next";

export default function About() {
  const currentYear = new Date().getFullYear();

  return (
  <>
  <h2 className={'content-block'}>{t("About Care4iT")}</h2>
  <div className={'content-block'}>
    <div className={'dx-card responsive-paddings'}>

      <p className="paragraph">
        <span className="strong">{t("Care4Check, Care4Commerce, and Care4Fresh are products of ")}</span>
        <a
          href="https://www.care4it.nl"
          target="_blank"
          rel="noopener noreferrer"
          className="link"
        >
          Care4iT b.v.
        </a>
      </p>
      <p className="paragraph">
        {t("Copyright © 2007-")}
        {currentYear} 
        {t(" Care4iT b.v. All rights reserved.")}
      </p>
      <p className="paragraph">
        <span className="strong">{t("Warning:")}</span> {t("Care4Check is protected by copyright. Unauthorized reproduction or distribution of Care4Check, or any part of it, is strictly prohibited and subject to legal penalties, and will be pursued with all available legal means.")}
      </p>
    </div>
    </div>
    </>
  );
}