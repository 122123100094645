import React from "react";
import { BACKENDURL } from "../../api/constants";

interface CustomTooltipProps {
  appointmentData: { id: number; text: string };
  fetchData: () => void;
  closeTooltip: () => void;
}

const CustomTooltipRender: React.FC<CustomTooltipProps> = ({
  appointmentData,
  fetchData,
  closeTooltip,
}) => {
  const handleDelete = async (appointmentId: number): Promise<void> => {
    try {
      const url = `${BACKENDURL}/api/checklists/${appointmentId}/schedule`;
      const response = await fetch(url, {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `Failed to delete the appointment: ${response.status} - ${errorText}`
        );
      }
      alert("Appointment deleted successfully!");
      fetchData();
      closeTooltip();
    } catch (error) {
      console.error("Error deleting appointment:", error);
      alert(`Error deleting appointment. Please try again.`);
    }
  };

  return (
    <div
      id="appointmentMessage"
      className="custom-tooltip"
      onDoubleClick={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
    >
      <span className="tooltip-text">{appointmentData.text}</span>
      <i
        style={{ fontSize: "20px" }}
        className="dx-icon-trash"
        onClick={(e) => {
          e.stopPropagation();
          handleDelete(appointmentData.id);
        }}
      ></i>
    </div>
  );
};

export default CustomTooltipRender;
