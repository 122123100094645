import Scheduler, {
  SchedulerTypes,
} from "devextreme-react/scheduler";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Appointment from "./appointment";
import {
  AppointmentAddedEvent,
  AppointmentDblClickEvent,
} from "devextreme/ui/scheduler_types";
import { DailyFrequencyDays } from "./dailyfrequency";
import "./schedulechecklists";
import { MonthlyFrequencyOn } from "./monthlyfrequencyon";
import { MonthlyFrequency } from "./monthlyfrequency";
import { WeeklyFrequencyDays } from "./weeklyfrequencydays";
import { Day } from "./days";
import { t } from "i18next";
import { BACKENDURL } from "../../api/constants";
import CustomTooltipRender from "./customtooltiprender";
const currentDate = new Date();
const views: SchedulerTypes.ViewType[] = ["month"];

interface IChecklist {
  idChecklist: number;
  code: string;
  name: string;
  description: string | null; // Assuming description can be null
  revisionNumber: number;
  revisionDate: string; // Using string to represent dates as ISO strings
  state: number;
  idAdministration: number;
  questions: any[] | null; // Specify more precise type if known
  schedules: any[] | null; // Specify more precise type if known
}
interface FormState {
  idChecklist: number | null;
  onceDate: string;
  startDate: string;
  endDate: string;
  enableFeature: boolean;
  repeatType: ScheduleType;
  repeatEvery: number;
  repeatOnDay: DailyFrequencyDays[];
  repeatOnDays: WeeklyFrequencyDays;
  repeatOnMonths: number[];
  repeatOnWeekdayOnMonths: boolean;
  monthlyFrequencyDay: number[];
  monthlyOnWeekDays: DailyFrequencyDays[];
  monthlyFrequencyOnWeekDay: DailyFrequencyDays[];
}

interface DayItem {
  day: string;
  value: DailyFrequencyDays;
}
interface ValueChangeEventArgs {
  value: DailyFrequencyDays[];
}
enum ScheduleType {
  Inactive = 0,
  Once = 1,
  Daily = 2,
  Weekly = 3,
  Monthly = 4,
}

export default function ScheduleChecklists() {
  const [checklists, setChecklists] = useState<IChecklist[]>([]);

  useEffect(() => {
    const fetchChecklists = async () => {
      try {
        const response = await fetch(
          `${BACKENDURL}/api/checklists?skip=0&take=100`,
          {
            credentials: "include",
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch checklists");
        }
        const data = await response.json();
        setChecklists(data); // Update state with fetched checklists
      } catch (error) {
        console.error("Error fetching checklists:", error);
      }
    };

    fetchChecklists();
  }, []); // Empty dependency array means this effect runs once after the initial render;

  const [formState, setFormState] = useState<FormState>({
    idChecklist: null,
    onceDate: "",
    startDate: "",
    endDate: "",
    enableFeature: false,
    repeatType: 1,
    repeatEvery: 0,
    repeatOnDay: [],
    repeatOnDays: 1,
    repeatOnMonths: [],
    repeatOnWeekdayOnMonths: false,
    monthlyFrequencyDay: [],
    monthlyOnWeekDays: [],
    monthlyFrequencyOnWeekDay: [],
  });
  const handleValueChange = (field: keyof FormState, value: any) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const onAppointmentFormOpening = async (
    e: SchedulerTypes.AppointmentFormOpeningEvent
  ): Promise<void> => {
    let initialRepeatState = false;

    e.form.option("items", [
      {
        dataField: "customFieldId",
        editorType: "dxSelectBox",
        editorOptions: {
          onValueChanged: (e: { value: any }) =>
            handleValueChange("idChecklist", e.value),
          dataSource: checklists,
          displayExpr: "name", // Display name in the dropdown
          valueExpr: "idChecklist", // Use idChecklist as the value of items
          searchEnabled: true,
          validationError: {
            message: "Selection of checklist is mandatory", // Custom error message
          },
          showClearButton: true, // Allows users to clear the input if needed
          isValid: true, // Starts with invalid state
        },
        colSpan: 2,
        label: {
          text: t("Checklist"),
        },
        validationRules: [
          { type: "required", message: "Checklist is a required field." },
        ],
      },
      {
        dataField: "onceDate",
        editorType: "dxDateBox",
        editorOptions: {
          onValueChanged: (e: { value: any }) =>
            handleValueChange("onceDate", e.value),
          type: "date",
          value: new Date(),
          displayFormat: "yyyy/MM/dd",
          useMaskBehavior: true,
          dateSerializationFormat: "yyyy-MM-dd",
          validationError: {
            message: "Date is mandatory", // Custom error message
          },
          isValid: true,
        },
        validationRules: [
          { type: "required", message: "Date is a required field." },
        ],
        label: {
          text: t("Date"),
        },
      },
      {
        dataField: "startDate",
        editorType: "dxDateBox",
        editorOptions: {
          type: "date",
          onValueChanged: (e: { value: any }) =>
            handleValueChange("startDate", e.value),
          displayFormat: "yyyy/MM/dd",
          useMaskBehavior: true,
          dateSerializationFormat: "yyyy-MM-dd",
        },
        label: {
          text: t("Start Date"),
        },
      },
      {
        dataField: "endDate",
        editorType: "dxDateBox",
        editorOptions: {
          type: "date",
          onValueChanged: (e: { value: any }) =>
            handleValueChange("endDate", e.value),
          displayFormat: "yyyy/MM/dd",
          useMaskBehavior: true,
          dateSerializationFormat: "yyyy-MM-dd",
        },
        label: {
          text: t("End Date"),
        },
      },
      {
        dataField: "enableFeature",
        editorType: "dxSwitch",
        editorOptions: {
          onText: "Enabled",
          offText: "Disabled",
          onValueChanged: function (e: { value: boolean }) {
            updateDateFieldsVisibility(e.value);
            handleValueChange("enableFeature", e.value); // Direct use of e.value for the current state
          },
        },
        label: {
          text: t("Repeat"),
        },
      },
      {
        dataField: "repeatType",
        editorType: "dxSelectBox",
        editorOptions: {
          dataSource: repeatOptions,
          displayExpr: t("name"),
          valueExpr: "id",
          onValueChanged: function (e: { component?: any; value?: any }): void {
            // Assuming `e.value` will give you the id of the selected option
            const selectedOption = repeatOptions.find(
              (option) => option.id === e.value
            );
            handleValueChange("repeatType", e.value);
            // Check if selectedOption is defined before proceeding
            if (selectedOption) {
              updateFormItemsVisibility(selectedOption);
            } else {
              console.error("Selected option is undefined");
              // Handle undefined case appropriately, maybe reset form or show an error
            }
          },
          validationError: {
            message: "Repeat type is mandatory", // Custom error message
          },
          showClearButton: true, // Allows users to clear the input if needed
          isValid: true, // Starts with invalid state
        },
        validationRules: [
          { type: "required", message: "Repeat type is a required field." },
        ],
        label: {
          text: t("Repeat"),
        },
      },
      {
        dataField: "repeatOnDay",
        editorType: "dxTagBox", // Change from dxTagBox to dxSelectBox
        editorOptions: {
          onValueChanged: (e: { value: any }) => {
            handleValueChange("repeatOnDay", e.value); // Directly use e.value
          },
          items: [
            { day: t("Sunday"), value: DailyFrequencyDays.Sunday },
            { day: t("Monday"), value: DailyFrequencyDays.Monday },
            { day: t("Tuesday"), value: DailyFrequencyDays.Tuesday },
            { day: t("Wednesday"), value: DailyFrequencyDays.Wednesday },
            { day: t("Thursday"), value: DailyFrequencyDays.Thursday },
            { day: t("Friday"), value: DailyFrequencyDays.Friday },
            { day: t("Saturday"), value: DailyFrequencyDays.Saturday },
          ] as DayItem[],
          displayExpr: "day",
          valueExpr: "value",
          showClearButton: true, // Allows users to clear the selected value
          searchEnabled: false, // Depending on your UI requirements, you can enable or disable searching within the dropdown
          validationError: {
            message: "Week days type is mandatory", // Custom error message
          },
          isValid: true, // Starts with invalid state
        },
        colSpan: 2,
        label: {
          text: t("Repeat On Day"),
        },
        validationRules: [
          { type: "required", message: "Week days is a required field." },
        ],
        visible: false, // Control visibility dynamically as needed
      },
      {
        dataField: "repeatOnDays",
        editorType: "dxSelectBox",
        editorOptions: {
          onValueChanged: (e: ValueChangeEventArgs) =>
            handleValueChange("repeatOnDays", e.value),
          items: [
            { day: t("Sunday"), value: WeeklyFrequencyDays.Sunday },
            { day: t("Monday"), value: WeeklyFrequencyDays.Monday },
            { day: t("Tuesday"), value: WeeklyFrequencyDays.Tuesday },
            { day: t("Wednesday"), value: WeeklyFrequencyDays.Wednesday },
            { day: t("Thursday"), value: WeeklyFrequencyDays.Thursday },
            { day: t("Friday"), value: WeeklyFrequencyDays.Friday },
            { day: t("Saturday"), value: WeeklyFrequencyDays.Saturday },
          ],
          displayExpr: "day",
          valueExpr: "value",
          multiline: false,
        },
        colSpan: 2,
        label: {
          text: t("Repeat On"),
        },
        validationRules: [
          { type: "required", message: "Week days is a required field." },
        ],
        visible: false,
      },
      {
        dataField: "repeatOnMonths",
        editorType: "dxTagBox",
        editorOptions: {
          onValueChanged: (e: { value: any }) =>
            handleValueChange("repeatOnMonths", e.value),
          items: [
            { month: t("January"), value: MonthlyFrequency.January },
            { month: t("February"), value: MonthlyFrequency.February },
            { month: t("March"), value: MonthlyFrequency.March },
            { month: t("April"), value: MonthlyFrequency.April },
            { month: t("May"), value: MonthlyFrequency.May },
            { month: t("June"), value: MonthlyFrequency.June },
            { month: t("July"), value: MonthlyFrequency.July },
            { month: t("August"), value: MonthlyFrequency.August },
            { month: t("September"), value: MonthlyFrequency.September },
            { month: t("October"), value: MonthlyFrequency.October },
            { month: t("November"), value: MonthlyFrequency.November },
            { month: t("December"), value: MonthlyFrequency.December },
          ],
          displayExpr: "month",
          valueExpr: "value",
          showClearButton: true,
        },
        colSpan: 2,
        label: {
          text: t("Repeat On (Month)"),
        },
        validationRules: [
          { type: "required", message: "Week days is a required field." },
        ],
        visible: false,
      },
      {
        dataField: "repeatOnWeekdayOnMonths",
        editorType: "dxRadioGroup",
        editorOptions: {
          onValueChanged: (e: { value: boolean }) => {
            const selectedOption = e.value;
            handleValueChange("repeatOnWeekdayOnMonths", e.value);
            updateMonthlyFrequencyFieldsVisibility(selectedOption);
          },
          items: [
            { label: t("Date"), value: false },
            // { label: t("Week Day"), value: true },
          ],
          valueExpr: "value",
          displayExpr: "label",
          layout: "horizontal",
        },
        label: {
          text: t("Repeat Frequency"),
        },
        visible: false,
      },
      {
        dataField: "monthlyFrequencyDay",
        editorType: "dxTagBox",
        editorOptions: {
          onValueChanged: (e: { value: any }) =>
            handleValueChange("monthlyFrequencyDay", e.value), //MonthlyFrequency
          dataSource: [
            { day: "Day1", value: 1 },
            { day: "Day2", value: 2 },
            { day: "Day3", value: 4 },
            { day: "Day4", value: 8 },
            { day: "Day5", value: 16 },
            { day: "Day6", value: 32 },
            { day: "Day7", value: 64 },
            { day: "Day8", value: 128 },
            { day: "Day9", value: 256 },
            { day: "Day10", value: 512 },
            { day: "Day11", value: 1024 },
            { day: "Day12", value: 2048 },
            { day: "Day13", value: 4096 },
            { day: "Day14", value: 8192 },
            { day: "Day15", value: 16384 },
            { day: "Day16", value: 32768 },
            { day: "Day17", value: 65536 },
            { day: "Day18", value: 131072 },
            { day: "Day19", value: 262144 },
            { day: "Day20", value: 524288 },
            { day: "Day21", value: 1048576 },
            { day: "Day22", value: 2097152 },
            { day: "Day23", value: 4194304 },
            { day: "Day24", value: 8388608 },
            { day: "Day25", value: 16777216 },
            { day: "Day26", value: 33554432 },
            { day: "Day27", value: 67108864 },
            { day: "Day28", value: 134217728 },
            { day: "Day29", value: 268435456 },
            { day: "Day30", value: 536870912 },
            { day: "Day31", value: 1073741824 },
            { day: "Last", value: 2147483648 },
          ],
          displayExpr: "day",
          valueExpr: "value",
          showClearButton: true,
          searchEnabled: false, // Depending on your UI requirements, you can enable or disable searching within the dropdown
          validationError: {
            message: "Date is mandatory", // Custom error message
          },
          isValid: true, // Starts with invalid state
        },
        label: {
          text: t("Repeat On (Date)"),
        },
        colSpan: 2,
        visible: false,
        validationRules: [
          { type: "required", message: "Date is a required field." },
        ],
      },
      {
        dataField: "monthlyOnWeekDays",
        editorType: "dxTagBox",
        editorOptions: {
          onValueChanged: (e: ValueChangeEventArgs) =>
            handleValueChange("monthlyOnWeekDays", e.value),
          items: [
            { week: t("First"), value: MonthlyFrequencyOn.First },
            { week: t("Second"), value: MonthlyFrequencyOn.Second },
            { week: t("Third"), value: MonthlyFrequencyOn.Third },
            { week: t("Fourth"), value: MonthlyFrequencyOn.Fourth },
            { week: t("Last"), value: MonthlyFrequencyOn.Last },
          ],
          displayExpr: "week",
          valueExpr: "value",
          multiline: false,
          searchEnabled: false, // Depending on your UI requirements, you can enable or disable searching within the dropdown
          validationError: {
            message: "Week is mandatory", // Custom error message
          },
          isValid: true, // Starts with invalid state
        },
        colSpan: 2,
        label: {
          text: t("Repeat On (Week)"),
        },
        visible: false,
        validationRules: [
          { type: "required", message: "Week is a required field." },
        ],
      },
      {
        dataField: "monthlyFrequencyOnWeekDay",
        editorType: "dxTagBox",
        editorOptions: {
          onValueChanged: (e: ValueChangeEventArgs) =>
            handleValueChange("monthlyFrequencyOnWeekDay", e.value),
          items: [
            { day: t("Sunday"), value: DailyFrequencyDays.Sunday },
            { day: t("Monday"), value: DailyFrequencyDays.Monday },
            { day: t("Tuesday"), value: DailyFrequencyDays.Tuesday },
            { day: t("Wednesday"), value: DailyFrequencyDays.Wednesday },
            { day: t("Thursday"), value: DailyFrequencyDays.Thursday },
            { day: t("Friday"), value: DailyFrequencyDays.Friday },
            { day: t("Saturday"), value: DailyFrequencyDays.Saturday },
          ] as DayItem[],
          displayExpr: "day",
          valueExpr: "value",
          multiline: false,
          searchEnabled: false, // Depending on your UI requirements, you can enable or disable searching within the dropdown
          validationError: {
            message: "Week is mandatory", // Custom error message
          },
          isValid: true, // Starts with invalid state
        },
        colSpan: 2,
        label: {
          text: t("Repeat On (Day)"),
        },
        visible: false,
        validationRules: [
          { type: "required", message: "Week day is a required field." },
        ],
      },
    ]);

    updateDateFieldsVisibility(initialRepeatState);

    function updateMonthlyFrequencyFieldsVisibility(
      isWeekdaySelected: boolean
    ): void {
      const form = e.form; // Assuming e.form is globally available or passed as an argument

      const items = form.option("items") as Array<any>;

      const monthlyFrequencyDayItem = items.find(
        (item) => item.dataField === "monthlyFrequencyDay"
      );
      const monthlyOnWeekDaysItem = items.find(
        (item) => item.dataField === "monthlyOnWeekDays"
      ); //monthlyFrequencyOnWeekDay
      const monthlyFrequencyOnWeekDayItem = items.find(
        (item) => item.dataField === "monthlyFrequencyOnWeekDay"
      );
      if (!isWeekdaySelected) {
        monthlyFrequencyDayItem.visible = true;
        monthlyOnWeekDaysItem.visible = false;
        monthlyFrequencyOnWeekDayItem.visible = false;
      } else {
        monthlyFrequencyDayItem.visible = false;
        monthlyOnWeekDaysItem.visible = true;
        monthlyFrequencyOnWeekDayItem.visible = true;
      }
      form.option("items", items);
    }
    function updateFormItemsVisibility(selectedOption: {
      id: number;
      name: string;
      config?: {
        type?: string;
        step?: number;
        days?: boolean;
        months?: boolean;
      };
    }): void {
      const form = e.form; // Assuming e.form is globally available or passed as an argument

      const items = form.option("items") as Array<any>;

      // Find the relevant form items by their dataField property
      const repeatOnDayItem = items.find(
        (item) => item.dataField === "repeatOnDay"
      );
      const repeatOnDaysItem = items.find(
        (item) => item.dataField === "repeatOnDays"
      );
      const repeatOnMonthsItem = items.find(
        (item) => item.dataField === "repeatOnMonths"
      );
      const repeatOnWeekdayOnMonths = items.find(
        (item) => item.dataField === "repeatOnWeekdayOnMonths"
      );

      if (repeatOnDayItem) repeatOnDayItem.visible = false;
      if (repeatOnDaysItem) repeatOnDaysItem.visible = false;
      if (repeatOnMonthsItem) {
        repeatOnMonthsItem.visible = false;
        repeatOnWeekdayOnMonths.visible = false;
      }

      // Update visibility based on the selectedOption ID
      if (selectedOption.id === 2 && repeatOnDayItem) {
        repeatOnDayItem.visible = true;
      } else if (selectedOption.id === 3 && repeatOnDaysItem) {
        repeatOnDaysItem.visible = true;
      } else if (selectedOption.id === 4 && repeatOnMonthsItem) {
        repeatOnMonthsItem.visible = true;
        repeatOnWeekdayOnMonths.visible = true;
      }

      // Apply the updated configuration back to the form
      form.option("items", items);
    }
    function updateDateFieldsVisibility(isVisible: boolean) {
      const form = e.form;
      const items = form.option("items") as any[]; // Typecasting to any, better use specific types if possible

      // Find the items in the form
      const dateItem = items.find((item) => item.dataField === "onceDate");

      const startDateItem = items.find(
        (item) => item.dataField === "startDate"
      );
      const endDateItem = items.find((item) => item.dataField === "endDate");
      const repeatDropdownItem = items.find(
        (item) => item.dataField === "repeatType"
      );
      const repeatOnDayItem = items.find(
        (item) => item.dataField === "repeatOnDay"
      );
      const repeatOnDaysItem = items.find(
        (item) => item.dataField === "repeatOnDays"
      );
      const repeatOnMonthsItem = items.find(
        (item) => item.dataField === "repeatOnMonths"
      ); 

      if (dateItem) {
        dateItem.visible = !isVisible;
      }

      // "Start Date" and "End Date" visibility
      if (startDateItem && endDateItem) {
        startDateItem.visible = isVisible;
        endDateItem.visible = isVisible;
      }

      // "Repeat" dropdown visibility (only shown if the toggle is on)
      if (repeatDropdownItem) {
        repeatDropdownItem.visible = isVisible;
      }

      if (repeatOnDayItem) {
        repeatOnDayItem.visible = isVisible && repeatOnDayItem.visible;
      }
      if (repeatOnDaysItem) {
        repeatOnDaysItem.visible = isVisible && repeatOnDaysItem.visible;
      }
      if (repeatOnMonthsItem) {
        repeatOnMonthsItem.visible = isVisible && repeatOnMonthsItem.visible;
      }
      // Re-apply the updated items to the form
      form.option("items", items);
    }
  };

  async function onAppointmentAdded(e: AppointmentAddedEvent): Promise<void> {
    const checklistId = formState.idChecklist; // Adjust property path as necessary

    const scheduleRequest = {
      description: "New Schedule",
      type: formState.repeatType,
      startDate: formState.startDate || null,
      endDate: formState.endDate || null,
      onceDate: formState.onceDate || new Date(),
      dailyFrequency: formState.repeatOnDay || 1,
      weeklyFrequency: formState.repeatOnDays || 0, // Convert weekday names to bitmask, // Monday and Saturday
      monthlyFrequencyMonth: formState.repeatOnMonths || 0, // January and December
      monthlyFrequencyDay: formState.monthlyFrequencyDay || null,
      monthlyFrequencyOn: formState.monthlyOnWeekDays || null,
      monthlyFrequencyOnDay: formState.monthlyFrequencyOnWeekDay || null,
    };

    const apiUrl = `${BACKENDURL}/api/checklists/${checklistId}/schedule`;

    const response = await fetch(apiUrl, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(scheduleRequest),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const updatedAppointments = await fetchData();
    setSchedulerData(updatedAppointments);
  }
  const navigate = useNavigate();
  const [schedulerData, setSchedulerData] = useState([]);

  const fetchData = async () => {
    const url = `${BACKENDURL}/api/checklists?skip=0&take=100&fetchSchedules=true`;
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();

    const appointments = data
      .map(
        (item: {
          idChecklistSchedule: number;
          schedules: any[];
          name: string;
          description: any;
        }) =>
          item.schedules.map(
            (schedule: {
              idChecklistSchedule: number;
              description: string;
              startDate: string | number | Date;
              endDate: string | number | Date;
              onceDate: string | number | Date;
              type: number;
              dailyFrequency?: number;
              weeklyFrequency?: number;
              monthlyFrequencyMonth?: number;
              monthlyFrequencyDay?: number;
              monthlyFrequencyOn?: number;
              monthlyFrequencyOnDay?: number;
            }) => {
              let recurrenceRule = "";
              let startDate = new Date(schedule.startDate);

              // Handling recurrence based on schedule type
              switch (schedule.type) {
                case 1: //Once
                  if (schedule.onceDate) {
                    startDate = new Date(schedule.onceDate);
                  }

                  break;
                case 2: // Daily frequency
                  const includedDays = findIncludedWeekDays(
                    schedule.dailyFrequency ?? 0
                  ).toString();

                  recurrenceRule += "FREQ=DAILY";

                  if (includedDays !== "") {
                    recurrenceRule += ";BYDAY=" + includedDays;
                  }

                  if (schedule.endDate != null) {
                    recurrenceRule +=
                      ";UNTIL=" + formatDate(new Date(schedule.endDate));
                  }

                  break;

                case 3: // Weekly frequency
                  recurrenceRule += "FREQ=WEEKLY";

                  const includedWeekDays = findIncludedDay(
                    schedule.weeklyFrequency ?? 0
                  ).toString();

                  if (includedWeekDays !== "") {
                    recurrenceRule += ";BYDAY=" + includedWeekDays;
                  }
                  if (schedule.endDate != null) {
                    recurrenceRule +=
                      ";UNTIL=" + formatDate(new Date(schedule.endDate));
                  }
                  break;

                case 4:
                  recurrenceRule += "FREQ=MONTHLY";
                  const includedMonths = findIncludedMonths(
                    schedule.monthlyFrequencyMonth ?? 0
                  ).toString();

                  if (includedMonths !== "") {
                    recurrenceRule += ";BYMONTH=" + includedMonths;
                  }

                  if (schedule.monthlyFrequencyDay !== 0) {
                    const includedDates = findIncludedDates(
                      schedule.monthlyFrequencyDay ?? 0
                    ).toString();

                    recurrenceRule += ";BYMONTHDAY=" + includedDates;
                  } else {
                    if (
                      schedule.monthlyFrequencyOn !== 0 &&
                      schedule.monthlyFrequencyOnDay !== 0
                    ) {
                      const includedWeekDays = findIncludedDay(
                        schedule.monthlyFrequencyOnDay ?? 0
                      ).toString();
                      recurrenceRule += ";BYDAY=" + includedWeekDays;
                    }
                  }

                  break;
              }

              return {
                id: schedule.idChecklistSchedule,
                text: `${item.name}`,
                startDate: new Date(startDate),
                endDate: new Date(new Date(startDate).setHours(13, 59, 59)),
                description: item.description,
                recurrenceRule: recurrenceRule,
                allDay: !schedule.startDate || !schedule.endDate,
              };
            }
          )
      )
      .flat(); 
    setSchedulerData(appointments);
    return appointments;
  };
  const handleAppointmentDoubleClick = (e: AppointmentDblClickEvent) => {
    e.cancel = true; 
  };

  useEffect(() => {
    fetchData().catch((error) => console.error(error));
  }, []);

  function formatDate(date: Date) {
    if (!date) return null;

    const year = date.getFullYear();
    const month = date.getMonth() + 1; 
    const day = date.getDate();

    const formattedMonth = month < 10 ? `0${month}` : month.toString();
    const formattedDay = day < 10 ? `0${day}` : day.toString();

    return `${year}${formattedMonth}${formattedDay}`;
  }

  function findIncludedDay(value: number): string {
    const dayAbbreviations: { [key: number]: string } = {
      0: "MO",
      1: "TU",
      2: "WE",
      3: "TH",
      4: "FR",
      5: "SA",
      6: "SU",
    };

    if (value >= 0 && value <= 6) {
      return dayAbbreviations[value];
    }

    return ""; 
  }

  function findIncludedWeekDays(value: number): string[] {
    const includedDays: string[] = [];

    const dayAbbreviations: { [key: string]: string } = {
      Monday: "MO",
      Tuesday: "TU",
      Wednesday: "WE",
      Thursday: "TH",
      Friday: "FR",
      Saturday: "SA",
      Sunday: "SU",
    };

    for (let day in DailyFrequencyDays) {
      const dayValue: number =
        DailyFrequencyDays[day as keyof typeof DailyFrequencyDays];

      if ((value & dayValue) !== 0) {
        const abbreviation = dayAbbreviations[day];
        if (abbreviation) {
          includedDays.push(abbreviation); 
        }
      }
    }
    return includedDays; 
  }

  function findIncludedMonths(value: number): number[] {
    const includedMonths: number[] = [];

    for (let month in MonthlyFrequency) {
      const monthValue: number =
        MonthlyFrequency[month as keyof typeof MonthlyFrequency];
      if ((value & monthValue) !== 0) {
        const monthIndex = Math.log2(monthValue) + 1; 
        includedMonths.push(monthIndex);
      }
    }
    return includedMonths;
  }

  function findIncludedDates(value: number): number[] {
    const includedDays: number[] = [];

    for (let day in Day) {
      const dayValue: number = Day[day as keyof typeof Day];

      if (day !== "Last" && (value & dayValue) !== 0) {
       
        const dayIndex = Math.log2(dayValue) + 1; 
        includedDays.push(dayIndex);
      }
    }

    if ((value & Day.Last) !== 0) {
      includedDays.push(32); 
    }

    return includedDays.sort((a, b) => a - b); 
  }

  const handelRefreshPage = () => {
    navigate("/home"); 
    setTimeout(() => {
      navigate("/schedulechecklists"); 
    }, 0); 
  };

  return (
    <React.Fragment>
      <Scheduler
        views={views}
        dataSource={schedulerData}
        defaultCurrentView="month"
        startDayHour={8}
        defaultCurrentDate={currentDate}
        onAppointmentDblClick={handleAppointmentDoubleClick}
        appointmentTooltipRender={(props) => (
          <CustomTooltipRender
            {...props}
            fetchData={fetchData}
            closeTooltip={handelRefreshPage}
          />
        )}
        onAppointmentDeleted={fetchData}
        appointmentComponent={Appointment}
        onAppointmentAdded={onAppointmentAdded}
        onAppointmentFormOpening={onAppointmentFormOpening}
      ></Scheduler>
    </React.Fragment>
  );
}
const repeatOptions = [
  {
    id: 2,
    name: "Daily",
    config: { type: "weeks", step: 1, days: true },
  },
  {
    id: 3,
    name: "Weekly",
    config: { type: "weeks", step: 1, days: true },
  },
];
