import React, { useEffect, useState } from "react";
import "./home.scss";
import Chart, {
  Legend,
  ValueAxis,
  Label,
  Export,
  Series,
  CommonSeriesSettings,
  Format,
  Size,
  Connector,
} from "devextreme-react/chart";
import { BACKENDURL } from "../../api/constants";
import { PieChart } from "devextreme-react";
import { t } from "i18next";

interface QualityChecksSummaryViewModel {
  scheduledOn: Date;
  active: number;
  expired: number;
  scheduled: number;
  finished: number;
}

interface PieChartViewModel {
  name: string;
  value: number;
}
const processData = (
  data: QualityChecksSummaryViewModel[]
): PieChartViewModel[] => {
  const today = new Date();
  const todayData = data.filter(
    (d) => new Date(d.scheduledOn).toDateString() === today.toDateString()
  );

  if (todayData.length > 0) {
    const todayEntry = todayData[0]; // Assuming there's only one entry per day
    const pieChartData: PieChartViewModel[] = [
      { name: t("Active"), value: todayEntry.active },
      { name: t("Expired"), value: todayEntry.expired },
      { name: t("Scheduled"), value: todayEntry.scheduled },
      { name: t("Finished"), value: todayEntry.finished },
    ];

    return pieChartData;
  }

  return [];
};
export default function Home() {
  const [summaryData, setSummaryData] = useState<
    QualityChecksSummaryViewModel[]
  >([]);
  const [pieChartData, setPieChartData] = useState<PieChartViewModel[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${BACKENDURL}/api/qualityChecks/summary?skip=0&take=100`, {
          method: "GET",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data: QualityChecksSummaryViewModel[] = await response.json();
        setSummaryData(data);
        const processedData = processData(data);
        setPieChartData(processedData);
        setIsLoading(false);
      } catch (e: any) {
        setError(e.message);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching data: {error}</div>;
  }

  return (
    <React.Fragment>
      <div className="piechart-container">
        <PieChart
          id="pie"
          dataSource={pieChartData}
          palette="Ocean"
          title={t("Checklists Distribution for Today")}
        >
          <Series argumentField="name" valueField="value">
            <Label visible={true}>
              <Connector visible={true} width={1} />
            </Label>
          </Series>

          <Size width={500} />
          <Export enabled={true} />
        </PieChart>
      </div>

      <div className="chart-container">
        <Chart
          id="chart"
          dataSource={summaryData}
          palette="Ocean"
          title={t("Checklists Summary")}
        >
          <CommonSeriesSettings
            argumentField="scheduledOn"
            type="bar"
            hoverMode="allArgumentPoints"
            selectionMode="allArgumentPoints"
          >
            <Label visible={true}>
              <Format type="fixedPoint" precision={0} />
            </Label>
          </CommonSeriesSettings>
          <ValueAxis>
            <Label visible={true} />
          </ValueAxis>
          <Series valueField="active" name={t("Active")} />
          <Series valueField="expired" name={t("Expired")} />
          <Series valueField="scheduled" name={t("Scheduled")} />
          <Series valueField="finished" name={t("Finished")} />
          <Legend verticalAlignment="bottom" horizontalAlignment="center" />
          <Export enabled={true} />
        </Chart>
      </div>
    </React.Fragment>
  );
}
