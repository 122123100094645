import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  DataGrid,
  Column,
  Paging,
  FilterRow,
} from "devextreme-react/data-grid";
import { Oval } from "react-loader-spinner"; // Import the loader component
import "./reports.css";
import { t } from "i18next";
import { BACKENDURL } from "../../api/constants";

export default function Reports() {
  const today = new Date().toISOString().split("T")[0];

  const getTwoWeeksAgoDate = (): string => {
    const today = new Date();
    const twoWeeksAgo = new Date(today.getTime() - 14 * 24 * 60 * 60 * 1000);
    return twoWeeksAgo.toISOString().split("T")[0];
  };

  const navigate = useNavigate();
  const [scheduledDateFrom, setScheduledDateFrom] = useState<string>(
    getTwoWeeksAgoDate()
  );
  const [scheduledDateUntil, setScheduledDateUntil] = useState<string>(today);
  const [stateActive, setStateActive] = useState<boolean>(false);
  const [stateFinished, setStateFinished] = useState<boolean>(false);
  const [stateExpired, setStateExpired] = useState<boolean>(false);
  const [stateScheduled, setStateScheduled] = useState<boolean>(false);

  interface IQualityCheck {
    idQualityCheck: number;
    scheduledOn: string;
    checkStarted: string | null;
    checkEnded: string | null;
    inspectorName: string | null;
    remarks: string | null;
    state: number;
    noOfQuestions: number;
    noOfQuestionsAnswered: number;
    checklist: IChecklist;
  }
  interface IChecklist {
    idChecklist: number;
    code: string;
    name: string;
    description: string;
    revisionNumber: number;
    revisionDate: string;
    state: number;
    idAdministration: number;
  }
  const [qualityChecks, setQualityChecks] = useState<IQualityCheck[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleRowClick = (e: { data: { idQualityCheck: any } }) => {
    navigate(`/details/${e.data.idQualityCheck}`);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const queryParams = new URLSearchParams({
      scheduledDateFrom,
      scheduledDateUntil,
      stateActive: stateActive.toString(),
      stateFinished: stateFinished.toString(),
      stateExpired: stateExpired.toString(),
      stateScheduled: stateScheduled.toString(),
      skip: "0",
      take: "100",
    });

    try {
      const response = await fetch(
        `${BACKENDURL}/api/qualityChecks?${queryParams}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setQualityChecks(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getStatusLabel = (status: number): string => {
    switch (status) {
      case 0:
        return t("Scheduled");
      case 1:
        return t("Active");
      case 2:
        return t("Finished");
      case 3:
        return t("Expired");
      default:
        return t("Unknown");
    }
  };

  return (
    <div className="container">
      <h2 className="form-group">{t("Quality Checks")}</h2>
      <div className="form-group">
        <label className="form-label">{t("Scheduled Date From:")}</label>
        <input
          className="form-input"
          type="date"
          value={scheduledDateFrom}
          onChange={(e) => setScheduledDateFrom(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label className="form-label">{t("Scheduled Date Until:")}</label>
        <input
          className="form-input"
          type="date"
          value={scheduledDateUntil}
          onChange={(e) => setScheduledDateUntil(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label className="form-label">
          <input
            className="form-checkbox"
            type="checkbox"
            checked={stateActive}
            onChange={(e) => setStateActive(e.target.checked)}
          />
          {t("Active")}
        </label>
        <label className="form-label">
          <input
            className="form-checkbox"
            type="checkbox"
            checked={stateFinished}
            onChange={(e) => setStateFinished(e.target.checked)}
          />
          {t("Finished")}
        </label>
        <label className="form-label">
          <input
            className="form-checkbox"
            type="checkbox"
            checked={stateExpired}
            onChange={(e) => setStateExpired(e.target.checked)}
          />
          {t("Expired")}
        </label>
        <label className="form-label">
          <input
            className="form-checkbox"
            type="checkbox"
            checked={stateScheduled}
            onChange={(e) => setStateScheduled(e.target.checked)}
          />
          {t("Scheduled")}
        </label>
      </div>
      <div className="button-container">
        <button className="submit-button" onClick={handleSubmit}>
          {t("Load Data")}
        </button>
      </div>

      <div className="data-grid-container">
        <DataGrid
          dataSource={qualityChecks}
          showBorders={true}
          rowAlternationEnabled={true}
          onRowClick={handleRowClick}
        >
          {loading && (
            <div className="loader-overlay">
              <Oval color="#00BFFF" height={80} width={80} />
            </div>
          )}
          <Paging enabled={true} defaultPageSize={10} />
          <FilterRow visible={true} />
          <Column dataField="idQualityCheck" caption="ID" width={70} />
          <Column
            dataField="checklist.name"
            caption={t("Checklist Name")}
            width={300}
          />
          <Column
            width={170}
            dataField="scheduledOn"
            caption={t("Scheduled On")}
            dataType="date"
          />
          <Column
            dataField="state"
            caption={t("State")}
            width={170}
            customizeText={({ value }) => getStatusLabel(value)}
          />
          <Column dataField="inspectorName" caption={t("Inspector Name")} />
        </DataGrid>
      </div>
    </div>
  );
}
