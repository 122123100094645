import React from "react";
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { t } from "i18next";
import { BACKENDURL } from "../../api/constants";

// Helper function to make fetch requests
const fetchData = async (
  url: RequestInfo | URL,
  options: RequestInit | undefined
) => {
  try {
    const response = await fetch(url, {
      ...options,
      credentials: "include", // This includes cookies in the request
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching data: ", error);
    throw error;
  }
};

export default function Checklists() {
  const dataSource = new CustomStore({
    key: "idChecklist",
    load: async (loadOptions) => {
      const url = `${BACKENDURL}/api/checklists?skip=${
        loadOptions.skip || 0
      }&take=${loadOptions.take || 20}`;
      return fetchData(url, { method: "GET" }).then((data) => ({
        data: data,
        totalCount: data.length,
      }));
    },
  });

  return (
    <React.Fragment>
      <h2 className={"content-block"}>{t("Checklists")}</h2>
      <DataGrid
        className={"dx-card wide-card"}
        dataSource={dataSource}
        showBorders={false}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
      >
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />
        <Column dataField={"idChecklist"} caption={t("ID")} width={70} />
        <Column dataField={"code"} caption={t("Code")} />
        <Column dataField={"name"} caption={t("Name")} />
        <Column dataField={"description"} caption={t("Description")} />
        <Column dataField={"revisionNumber"} caption={t("Revision Number")} />
        <Column
          dataField={"revisionDate"}
          caption={t("Revision Date")}
          dataType={"date"}
          format={"HH:mm dd.MM.yyyy"} 
        />
      </DataGrid>
    </React.Fragment>
  );
}
