export enum Day {
  Day1 = 1,
  Day2 = 2,
  Day3 = 4,
  Day4 = 8,
  Day5 = 16,
  Day6 = 32,
  Day7 = 64,
  Day8 = 128,
  Day9 = 256,
  Day10 = 512,
  Day11 = 1024,
  Day12 = 2048,
  Day13 = 4096,
  Day14 = 8192,
  Day15 = 16384,
  Day16 = 32768,
  Day17 = 65536,
  Day18 = 131072,
  Day19 = 262144,
  Day20 = 524288,
  Day21 = 1048576,
  Day22 = 2097152,
  Day23 = 4194304,
  Day24 = 8388608,
  Day25 = 16777216,
  Day26 = 33554432,
  Day27 = 67108864,
  Day28 = 134217728,
  Day29 = 268435456,
  Day30 = 536870912,
  Day31 = 1073741824,
  Last = 2147483648,
}
