import React, { useEffect, useState } from "react";
import "./profile.scss";
import { useTranslation } from "react-i18next";
import { BACKENDURL } from "../../api/constants";

interface IUser {
  notes: string;
  idUser: number;
  userName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  displayName: string | null;
  profilePicture: Uint8Array | null;
}


const Profile: React.FC = () => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng: string | undefined) => {
    i18n.changeLanguage(lng);
   };

  const [notes, setNotes] = useState<string>("");
  const [employee, setEmployee] = useState<IUser | undefined>(undefined);
  const [profilePicture, setProfilePicture] = useState<string>("");

  const getImageSrc = (base64String: Uint8Array): string => {
    return `data:image/jpeg;base64,${base64String}`;
  };

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await fetch(`${BACKENDURL}/api/user`, {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data: IUser = await response.json();
        setEmployee(data);
        setNotes(data.notes || "");
        if (data.profilePicture) {
          setProfilePicture(getImageSrc(data.profilePicture));
        }
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };
    fetchEmployeeData();
  }, [i18n]);

  if (!employee) {
    return <div>Loading...</div>;
  }

  return (
    <div className="profile-container">
      <h2>{t("Profile")}</h2>
      <div className="profile-card">
        <div className="profile-container">
          {profilePicture ? (
            <img
              src={profilePicture}
              alt="Profile"
              style={{ width: "200px", height: "250px" }}
            />
          ) : (
            <p>No profile picture available</p>
          )}
          <div className="language-buttons">
            <button onClick={() => changeLanguage("en")}>English</button>
            <button onClick={() => changeLanguage("nl")}>Dutch</button>
          </div>
          <span className="notes">{notes}</span>
        </div>
        <div className="profile-details">
          <div className="form-group">
            <label>ID</label>
            <input
              type="number"
              name="idUser"
              value={employee.idUser}
              readOnly
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>{t("Username")}</label>
            <input
              type="text"
              name="userName"
              value={employee.userName || ""}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>{t("Email")}</label>
            <input
              type="email"
              name="email"
              value={employee.email || ""}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>{t("First Name")}</label>
            <input
              type="text"
              name="firstName"
              value={employee.firstName || ""}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>{t("Last Name")}</label>
            <input
              type="text"
              name="lastName"
              value={employee.lastName || ""}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>{t("Display Name")}</label>
            <input
              type="text"
              name="displayName"
              value={employee.displayName || ""}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>{t("Notes")}</label>
            <textarea name="notes" className="form-control"></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
