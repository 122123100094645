import {
  HomePage,
  ChecklistsPage,
  ProfilePage,
  ScheduleChecklistsPage,
  ReportsPage,
  QualityCheckDetailPage,
  AboutPage,
} from "./pages";
import { withNavigationWatcher } from "./contexts/navigation";

const routes = [
  {
    path: "/checklists",
    element: ChecklistsPage,
  },
  {
    path: "/profile",
    element: ProfilePage,
  },
  {
    path: "/home",
    element: HomePage,
  },
  {
    path: "/schedulechecklists",
    element: ScheduleChecklistsPage,
  },
  {
    path: "/reports",
    element: ReportsPage,
  },
  {
    path: "/details/:id",
    element: QualityCheckDetailPage,
  },
  {
    path: "/about",
    element: AboutPage,
  },
];

export default routes.map((route) => {
  return {
    ...route,
    element: withNavigationWatcher(route.element, route.path),
  };
});
